<template>
  <App
    title="绑定用户"
    @click-left="onNavBarClickLeft"
    left-arrow
    :showFooterTabBar="true">
    <Cell>
      <Button type="primary" @click="doShareMiniProgram">分享小程序</Button>
    </Cell>
  </App>
</template>

<script>
// eslint-disable-next-line no-undef
import { Cell, Notify, Button } from 'vant'
import { wxAgentConfig, wxSendChatMessage } from '@/utils/wechart'

export default {
  components: { Cell, Button },
  data () {
    return {
      loading: false}
  },
  mounted () {
    this.getConfig()
  },
  methods: {
    async getConfig () {
      const arrHref = document.location.href.split('#')
      await wxAgentConfig(['sendChatMessage'], encodeURIComponent(arrHref[0]))
    },
    // 分享小程序
    async doShareMiniProgram() {
      /* const result = await wxSendChatMessage({
        store_code: JSON.parse(localStorage.getItem('storeInfo')).code,
        ldap_userid: localStorage.getItem('ldap_userid').ldap_userid
      }) */
      const result = await wxSendChatMessage()
      // eslint-disable-next-line no-undef
      console.log(result, 'result==================')
      if (result) {
        // eslint-disable-next-line no-undef
        Notify({
          message: '分享成功',
          type: 'success',
          duration: 0
        })
        return false
      } else {
        // eslint-disable-next-line no-undef
        Notify({
          message: '分享失败',
          type: 'waring',
          duration: 0
        })
        return false
      }
    },
    // 返回
    onNavBarClickLeft () {
      this.$router.go(-1)
    }
  }
}
// wx460ff2ad9f82f7a9
// pages/phoneLogin/index

</script>

<style lang="less" scoped>
@import '@/style/common.less';
@import '@/style/vant-patch.less';
@import '@/style/var.less';
.search {
  padding: @padding-lg @padding-xl;
  background-color: @white;
  display: flex;
  align-items: center;
  .van-search {
    flex: 1;
  }
}
.account {
  background-color: @white;
  font-size  : @line-height-lg;
  padding: @padding-xl;
  border-bottom: 1px solid @gray-5;
}
</style>
